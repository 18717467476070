import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { GlobalController } from '../controllers/global.controller'
import { AlertProvider, HoursWorkedEntry } from '../providers/alerts.provider'
import { AuthProvider } from '../providers/auth.provider'
import { CommunicationProvider } from '../providers/communication.provider'
import { MESSAGE_TYPES } from 'src/app/utils/constants.utils'

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  communicationsAlerts: number = 0
  generalAlerts: number = 0
  assignationAlerts: number = 0
  sitesAlerts: number = 0
  hourAlerts: number = 0

  constructor(
    private authProvider: AuthProvider,
    private router: Router,
    public globalCtrl: GlobalController,
    private communicationProvider: CommunicationProvider,
    private alertProvider: AlertProvider
  ) {}

  // TODO: Use MessageType instead of string
  // getNotifications(type?: MessageType): number {
  getNotifications(type?: string): number {
    if (type === MESSAGE_TYPES.ASSIGNATION) {
      return this.assignationAlerts
    } else if (type === MESSAGE_TYPES.GENERAL) {
      return this.generalAlerts
    } else if (type === MESSAGE_TYPES.SITES_ALERTS) {
      return this.sitesAlerts
    } else if (type === MESSAGE_TYPES.HOUR_ALERTS) {
      return this.hourAlerts
    } else {
      return this.communicationsAlerts
    }
  }

  setMessagesNotifications(alerts, type) {
    type == MESSAGE_TYPES.GENERAL ? (this.generalAlerts = alerts) : (this.assignationAlerts = alerts)
    this.communicationsAlerts = this.generalAlerts + this.assignationAlerts
  }

  updateMessageAlerts() {
    this.communicationProvider.getAllUnseenCommunications(MESSAGE_TYPES.ASSIGNATION).then((alerts: any) => {
      this.assignationAlerts = alerts
    })
    this.communicationProvider.getAllUnseenCommunications(MESSAGE_TYPES.GENERAL).then((alerts: any) => {
      this.generalAlerts = alerts
    })
    this.communicationsAlerts = this.generalAlerts + this.assignationAlerts
  }

  updateHourAlerts() {
    this.alertProvider.getHoursWorked().then((res: HoursWorkedEntry[]) => {
      this.hourAlerts = res.length
    })
  }

  updateAlerts(days: number) {
    this.alertProvider.getAlertsNotifications(days).then((res: any) => {
      this.sitesAlerts = res.numberOfAlerts
    })
  }
}
